import { Component, signal, WritableSignal, OnInit, Signal, computed } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Media } from '@/common/models';
import { AuthService } from '@/pages/auth/services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  readonly companyMedia: WritableSignal<Media | null | undefined> = signal<Media | null | undefined>(null);
  readonly currentRoute: WritableSignal<string> = signal<string>('');

  readonly isCompanyButtonHidden: Signal<boolean> = computed(() => this.currentRoute().includes('/company'));

  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.companyMedia.set(this._authService.getUser()?.entity.media);

    // Subscribe to route changes
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Update current route
        this.currentRoute.set(event.urlAfterRedirects);
      }
    });

    // Alternatively, to get the initial route
    this.currentRoute.set(this._router.url);
  }

  onClick(): void {
    this._router.navigate(['/company/edit']).then(r => {
      !r && console.error('Navigation failed in footer.component/onClick');
    });
  }
}

